<template>
  <div class="constrain part download-block">
    <div v-for="element in elements" :key="element.id" class="row-12 mb-16">
      <div class="md:col-3 md:offset-2">
        <h4 class="mb-8 md:mb-0" v-html="element.titel" />
      </div>
      <div class="md:col-5 md:offset-0 flex-row items-start">
        <div v-if="element.beschreibung" class="description mb-6" v-html="element.beschreibung" />
        <Go
          v-for="file in element.dateien"
          :key="file.id"
          :to="file.link"
          class="file-item mb-6 flex items-center self-start"
          target="_blank"
        >
          <File />
          <div class="ml-6 md:ml-4">
            <p class="text-navy">
              {{ file.name.split('.')[0] }}
            </p>
          </div>
        </Go>
      </div>
    </div>
  </div>
</template>

<script>

import File from '../../assets/images/file.svg';

export default {
  components: {
    File,
  },
  props: {
    payload: Object,
  },
  computed: {
    elements() {
      return this.payload.elemente;
    },
  },
  mounted() {
    // console.log(this.elements);
  },
};
</script>

<style lang="scss" scoped>

.description {
  &:deep(p) {
    color: var(--color-navy);
  }
}

  .file-item {
    svg {
      width: px(30);
      height: px(30);
      @screen md {
        width: px(40);
        height: px(40);
      }
      @screen lg {
        width: px(45);
        height: px(45);
      }
    }
    p {
      background-image: linear-gradient(var(--color-navy), var(--color-navy));
      background-size: 0 px(2);
      background-repeat: no-repeat;
      background-position: bottom left;
      will-change: background-size;
      transition: background-size .25s ease-out;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover p {
        background-size: 100% px(2);
        color: var(--color-navy);
      }
    }
  }

</style>
